body {
  margin: 0;
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.navbar a {
  text-decoration: none !important;
}


/* override page loading progress bar styles*/
#nprogress .bar {
  background: whitesmoke !important;
  height: 3px !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px white, 0 0 5px white !important;
}

.dashboard-table, .dashboard-table td, .dashboard-table th {
  border: 1px solid black;
}

.table-tiny th,
.table-tiny td {
  padding-left: 4px;
  padding-right: 4px;
}

/* react data table pointer for rows */
.rdt_TableRow {
  cursor: pointer;
}

/* prevent text selection */
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

a.active {
  font-weight: bold;
}

.vessel-status-bar {
   width: 60%;
   margin: 0 auto;
   border: 1px black var(--chakra-colors-gray-100);
   background-color: var(--chakra-colors-gray-50);
   padding: 10px;
   margin-top: 10px;
   border-radius: 10px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.315);
}

.mindchip-logo {
   padding: 10px;
}

.navbar-dropdowns a:hover {
   color: white !important;
   text-decoration: none;
}

.leaflet-top.leaflet-left {
   z-index: 501;
}

.video-js.vjs-big-play-centered {
   width: 360px;
   height: 180px;
}


.container-tile {
   font-size: 0.9rem;
   font-family: Arial, Helvetica, sans-serif;
   justify-content: center;
   align-items: center;
   position: relative;
   width: 500px;
   height: 100%;
   border-radius: 0.5rem;
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.215);
   padding-bottom: 40px;
}

.container-tile:hover {
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.315);
}

.container__profile__text {
   padding: 10px;
   border-top: 1px solid var(--chakra-colors-gray-300);
}

.container__vessel__data {
   text-transform: uppercase;
   border-bottom-left-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
   border-top: 1px solid var(--chakra-colors-gray-300);
   width: 100%;
   position: absolute;
   bottom: 0;
   padding: 2%;
}

button.realtime-button {
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   color: whitesmoke;
   width: fit-content;
}

button.realtime-button:hover {
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.btn-group.btn-group-toggle.offset-sm-2 {
  display: contents;
}

.form-group.row.d-flex.align-items-sm-center > div.col-sm-1 {
  width: fit-content;
}

.form-group.row.d-flex.align-items-sm-center {
  margin-top: 15px;
  margin-bottom: 15px;
}

form div.px-3 > div:nth-child(2) {
  padding-bottom: 5px;
}